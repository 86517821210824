import * as React from "react";

const SingleBanner = ({ bgcolor, heading, text }) => {
  const textColour = bgcolor !== "white" ? "text-white" : "text-black";

  return (
    <div
      className={`flex flex-col items-center justify-center ${bgcolor} px-10 py-20 md:px-10 md:py-28 lg:px-24 xl:px-32`}
      id={"SingleBanner"}
    >
      <h2
        className={`text-3xl md:text-5xl lg:text-6xl xl:text-7xl font-bold pb-8 lg:pb-16 font-body ${textColour}`}
      >
        {heading}
      </h2>
      <p className="text-xl lg:text-2xl xl:text-2xl font-body text-center leading-relaxed xl:leading-loose font-lexend-deca">
        {text}
      </p>
    </div>
  );
};

export default SingleBanner;
