import * as React from "react";

const ColumnsBannerBlue = ({ columns, heading }) => {
  return (
    <div
      className={`flex flex-col items-center justify-center bg-creamyBlue px-10 py-20 md:px-10 md:py-28 lg:px-24 xl:px-32`}
    >
      <h2
        className={`text-3xl md:text-5xl lg:text-6xl xl:text-7xl font-bold pb-12 lg:pb-16 xl:pb-36 font-body text-white`}
      >
        {heading}
      </h2>
      <div className="flex flex-col md:flex-row items-stretch justify-between w-[100%]">
        {columns.map((column) => (
          <div className="flex flex-col items-start md:items-center">
            <div className="">
              <h2 class="py-0 text-6xl xl:text-6xl font-bold md:py-3 md:pb-0 px-2 font-body text-white">
                {column.title}
              </h2>
            </div>
            <div className="w-[100%]  pb-16 pt-5 md:pb-0 md:px-4 md:pt-5 xl:pt-10 text-left md:text-center font-lexend-deca text-lg lg:text-xl xl:text-2xl">
              <h2>{column.text}</h2>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ColumnsBannerBlue;
