import * as React from "react";
import ColumnsBannerBlue from "../components/fullWidthBanner/columns/bluebackground.js";
import ColumnsBannerWhite from "../components/fullWidthBanner/columns/whitebackground.js";
import SingleBanner from "../components/fullWidthBanner/single/index.js";

import HeroComponent from "../components/hero/index.js";
import Leadgen from "../components/leadgen/singlecolsignup.js";
import NavBar from "../components/navbar/index.js";
import Layout from "../components/Layout.js";

const nutshellColumns = [
  {
    title: "We",
    text: "Provide students with career insight, multi- disciplinary skills, and industry connections",
  },
  {
    title: "By",
    text: "Formal events, networking opportunities, and fun socials",
  },
  {
    title: "To",
    text: "Maximise their opportunities and fast- track their success in a COMM-STEM career.",
  },
];

const impactColumns = [
  {
    title: "1000+",
    text: "students who have engaged with our events and professional opportunities.",
  },
  {
    title: "6+",
    text: "sponsors who support our operations and initiatives.",
  },
  {
    title: "16+",
    text: "events held every year, from professional networking to fun socials.",
  },
];

// markup
const IndexPage = () => {
  return (
    <Layout>
      <main>
        <HeroComponent
          page={"Home"}
          heading={"Bridging the gap between business and STEM"}
          subheading={"A University of Sydney non-profit student society"}
          formText={"Enter your email to be a member"}
          formCTA={"Sign Up"}
          punctuation={"."}
        ></HeroComponent>
        <SingleBanner
          bgcolor={"bg-skyBlue"}
          heading={"Our Mission"}
          text={
            "The University of Sydney COMM-STEM Society aims to equip students studying combined commerce and STEM degrees with career insight, multi-disciplinary skills, and industry connections to maximise their success in their professional career."
          }
        ></SingleBanner>
        <ColumnsBannerWhite
          heading={"Us in a nutshell ..."}
          columns={nutshellColumns}
        ></ColumnsBannerWhite>
        <ColumnsBannerBlue
          heading={"Our impact"}
          columns={impactColumns}
        ></ColumnsBannerBlue>
        <Leadgen
          heading={"Sounds cool?"}
          text={
            "Join us to be connected to industry professionals, career opportunities, and likeminded students."
          }
        ></Leadgen>
      </main>
    </Layout>
  );
};

export default IndexPage;
