import * as React from "react";

const ColumnsBannerWhite = ({ columns, heading }) => {
  return (
    <div
      className={`flex flex-col items-center justify-center px-10 py-20 md:px-10 md:py-28 lg:px-24 xl:px-32`}
    >
      <h2
        className={`text-3xl md:text-5xl lg:text-6xl xl:text-7xl font-bold pb-12 lg:pb-16 xl:pb-36 font-body text-blue`}
      >
        {heading}
      </h2>
      <div className="flex flex-col md:flex-row items-stretch justify-between w-[100%]">
        {columns.map((column) => (
          <div className="flex flex-col items-center">
            <div className="bg-blue w-[100%] md:w-32 lg:w-36 xl:w-60">
              <h2 class="text-4xl xl:text-6xl font-bold py-3 px-2 font-body text-white">
                {column.title}
              </h2>
            </div>
            <div className="w-[100%] pb-12 md:pb-0 md:w-60 xl:w-96 pt-5 xl:pt-10 text-left md:text-center font-lexend-deca text-lg lg:text-xl xl:text-2xl">
              <h2>{column.text}</h2>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ColumnsBannerWhite;
