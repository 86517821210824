import * as React from "react";
import SignUp from "../hero/signup";

const Leadgen = ({ heading, text }) => {
  return (
    <div
      className={`flex flex-col items-center justify-center px-10 py-20 md:px-10 md:py-24 lg:px-24 xl:px-32`}
    >
      <h2
        className={`text-3xl md:text-5xl lg:text-6xl xl:text-7xl font-bold pb-16 font-body `}
      >
        {heading}
      </h2>
      <p className="text-lg lg:text-xl xl:text-2xl font-lexend-deca text-center pb-16">
        {text}
      </p>
      <SignUp width={"w-[50%]"} loc={"center"}></SignUp>
    </div>
  );
};

export default Leadgen;
